
import { defineComponent } from "vue";

export default defineComponent({
  name: "Kibana",
  data(){
      return {
          isShowIFrame : true
      }
  },
  mounted(this:{ $el : any , isShowIFrame : boolean , $route : any}) {
   const targetElm = this.$el;
   
   const userAgent = window.navigator.userAgent.toLowerCase();
  
   if(userAgent.indexOf('msie') != -1 ||
        userAgent.indexOf('trident') != -1) {
    console.log('Internet Explorer');
    } else if(userAgent.indexOf('edge') != -1) {
        console.log('Edgeをお使いですね');
    } else if(userAgent.indexOf('chrome') != -1) {
        console.log('Google Chrome');
    } else if(userAgent.indexOf('safari') != -1) {
        console.log('Safari');
    	
        this.isShowIFrame = false;
        return;
    } else if(userAgent.indexOf('firefox') != -1) {
        console.log('FireFox');
    } else if(userAgent.indexOf('opera') != -1) {
        console.log('Opera');
    } 
 
  targetElm.onload = function(){

        targetElm.contentWindow.document.querySelector('.euiAvatar').style.display='none';
  	 	console.log(targetElm.contentWindow.document.querySelector('.euiAvatar'));
  	}
  },
  methods :{
      openKibanaWindow (){
          	window.open( 'https://paletteiot.kb.japaneast.azure.elastic-cloud.com/s/'+ this.$route.params.space +'/app/dashboards#/' ,'Kibana');
      }
      
  }
});
